<template>
  <div>
    <b-card class="orders-header">
      <h5 class="mb-0">
        {{ $t('ordersZonesSelectorLabel') }}
      </h5>
      <v-select
        v-model="selectedZoneId"
        class="orders-header__select"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="zoneName"
        :clearable="false"
        :reduce="(i) => i.zoneId"
        :options="optionsZones"
      >
        <template #no-options>
          {{ $t('The list is empty') }}
        </template>
      </v-select>
      <b-spinner
        v-if="isSetupInProgress"
        variant="primary"
        class="ml-1"
      />
    </b-card>
    <template v-if="!isSetupInProgress">
      <template v-if="getTables.length">
        <orders-zone
          v-for="zone in zonesToRender"
          :key="zone.zoneId"
          :zone="zone"
          :selected-table-id="selectedTableId"
          @onTableClick="$emit('onTableClick', $event)"
        />
      </template>
      <template v-else>
        <div style="max-width: 500px">
          {{ $t('ordersNoZonesMessage') }}
        </div>
        <b-button
          :to="{ name: 'zones' }"
          class="mt-1"
          variant="primary"
        >
          {{ $t('ordersNoZonesButtonName') }}
        </b-button>
      </template>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import OrdersZone from '@/components/orders/OrdersZone.vue'

export default {
  name: 'OrdersZones',
  components: {
    BCard,
    BSpinner,
    BButton,
    OrdersZone,
  },
  props: {
    isSetupInProgress: {
      type: Boolean,
      required: true,
      default: true,
    },
    getTables: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedTableId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      selectedZoneId: null,
    }
  },
  computed: {
    optionsZones() {
      return [
        {
          zoneId: null,
          zoneName: this.$t('All Zones'),
        },
        ...this.getTables,
      ]
    },
    zonesToRender() {
      return this.selectedZoneId
        ? [this.getTables.find(i => i.zoneId === this.selectedZoneId)]
        : this.getTables
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-select.scss'

  .orders-header
    &__select
      margin-left: 1rem
      min-width: 200px
    .card-body
      display: flex
      align-items: center
</style>
