<template>
  <tr class="session-table__tr">
    <td class="session-table__td">
      <b-link @click="onClickName">
        {{ itemData.name }}
      </b-link>
      <div class="font-small-2 pl-75 pr-75">
        <div
          v-for="option in item.options"
          :key="option"
        >
          {{ getOrderItemOptionOutput(option) }}
        </div>
        <div
          v-for="extra in item.extras"
          :key="extra"
        >
          {{ getOrderItemExtraOutput(extra) }}
        </div>
        <div
          v-if="item.note"
          class="font-weight-bolder"
        >
          {{ item.note }}
        </div>
      </div>
    </td>
    <td class="session-table__td">
      <div class="session-table__item-price">
        {{ item.quantity }} * <currency-symbol>{{ getFormattedPrice(price) }}</currency-symbol>
      </div>
    </td>
    <td class="session-table__td">
      <div class="session-table__item-price">
        <currency-symbol>{{ getFormattedPrice(total) }}</currency-symbol>
      </div>
      <div class="session-table__item-actions">
        <feather-icon
          class="text-primary mr-50"
          icon="EditIcon"
          role="button"
          size="16"
          @click="onClickEdit"
        />
        <feather-icon
          class="text-danger"
          icon="TrashIcon"
          role="button"
          size="16"
          @click="onClickDelete"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinFormatter from '@/mixins/formatter'
import { getOrderItemOptionOutput, getOrderItemExtraOutput } from '@/order'

export default {
  name: 'OrdersSessionToBeAddedItem',
  components: {
    BLink,
    CurrencySymbol,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    menus: {
      type: Array,
      required: true,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    itemData() {
      const menu = this.menus.find(i => i.categories.find(j => j.items.find(k => k.id === this.item.offerId)))
      const category = menu.categories.find(i => i.items.find(j => j.id === this.item.offerId))
      return category.items.find(i => i.id === this.item.offerId)
    },
    price() {
      let result = this.itemData.price

      this.item.options.forEach(i => {
        const option = this.itemData.options.find(j => j.items.find(k => k.id === i))
        if (option) {
          const optionItem = option.items.find(j => j.id === i)
          if (optionItem) {
            result += optionItem.addPrice
          }
        }
      })
      this.item.extras.forEach(i => {
        const extra = this.itemData.extras.find(j => j.items.find(k => k.id === i || k.originalMenuItemId === i))
        if (extra) {
          const extraItem = extra.items.find(j => j.id === i || j.originalMenuItemId === i)
          if (extraItem) {
            result += extraItem.addPrice
          }
        }
      })

      return result
    },
    total() {
      return this.item.quantity * this.price
    },
  },
  methods: {
    getOrderItemOptionOutput(optionId) {
      return getOrderItemOptionOutput(optionId, this.itemData)
    },
    getOrderItemExtraOutput(extraId) {
      return getOrderItemExtraOutput(
        extraId,
        this.itemData,
        this.menus.flatMap(i => i.categories.flatMap(j => j.items)),
      )
    },
    onClickName() {
      this.$emit('edit')
    },
    onClickEdit() {
      this.$emit('edit')
    },
    onClickDelete() {
      this.$emit('delete')
    },
  },
}
</script>
