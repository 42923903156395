import { render, staticRenderFns } from "./OrdersZones.vue?vue&type=template&id=696a174e"
import script from "./OrdersZones.vue?vue&type=script&lang=js"
export * from "./OrdersZones.vue?vue&type=script&lang=js"
import style0 from "./OrdersZones.vue?vue&type=style&index=0&id=696a174e&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports