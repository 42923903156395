<template>
  <svg
    class="icon-custom"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.32941 5.93615C7.69807 2.4252 6.84949 1.09151 4.18425 1.14731C3.23478 1.16663 3.46231 0.459004 2.73823 0.727317C2.01557 0.995629 2.63591 1.38844 1.89608 1.9959C-0.180295 3.70093 0.0107429 5.27789 0.973805 9.03641C1.37949 10.6198 -0.00428262 10.6971 0.543074 12.2311C0.943039 13.3502 3.8909 13.8188 7.00046 12.6654C10.1107 11.5113 12.0705 9.22101 11.6705 8.10197C11.1232 6.56722 10.017 7.41437 9.32941 5.93615ZM6.66346 11.7195C3.88589 12.7499 1.60344 12.1445 1.50685 11.8748C1.34086 11.4097 2.40337 9.85995 5.57876 8.68152C8.75415 7.5031 10.5264 7.94027 10.7118 8.459C10.8212 8.76595 9.44174 10.6885 6.66346 11.7195ZM5.77051 9.21958C4.31876 9.75835 3.30991 10.3744 2.65594 10.9289C3.11601 11.3468 3.97747 11.4484 4.83536 11.13C5.92792 10.7257 6.59978 9.79484 6.33433 9.05215L6.32288 9.02639C6.14329 9.08506 5.9594 9.14874 5.77051 9.21958Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconBell',
}
</script>
