<template>
  <div class="orders-zone">
    <div class="orders-zone__header">
      {{ zone.zoneName }}
    </div>
    <div class="orders-zone__body">
      <template v-if="zone.tables.length">
        <orders-table
          v-for="table in zone.tables"
          :key="table.id"
          :table="table"
          :selected-table-id="selectedTableId"
          @click.native="onClickTable(table.id)"
        />
      </template>
      <div v-else>
        <div style="max-width: 500px">
          <small>{{ $t('ordersNoTablesMessage') }}</small>
        </div>
        <b-button
          :to="{ name: 'zone', params: { id: zone.zoneId } }"
          variant="primary"
          size="sm"
          class="mt-25"
        >
          {{ $t('ordersNoTablesButtonName') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersTable from '@/components/orders/OrdersTable.vue'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'OrdersZone',
  components: {
    BButton,
    OrdersTable,
  },
  props: {
    zone: {
      type: Object,
      required: true,
      default: () => {},
    },
    selectedTableId: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    onClickTable(tableId) {
      this.$emit('onTableClick', tableId)
    },
  },
}
</script>

<style lang="sass">
  .orders-zone
    & + .orders-zone
      margin-top: 30px
    &__body
      display: flex
      flex-wrap: wrap
      margin-top: 12px
      margin-right: -28px
      margin-bottom: -16px
      .orders-table
        margin-right: 28px
        margin-bottom: 16px
</style>
