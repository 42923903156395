<template>
  <b-card class="orders-add">
    <template v-if="isPanelAddComplex">
      <b-row>
        <b-col class="d-flex align-items-center">
          <feather-icon
            v-if="!isPanelAddEditMode"
            icon="ChevronLeftIcon"
            size="20"
            role="button"
            class="mr-1"
            @click="onClickCloseComplexPanel"
          />
          <h4 class="mb-0">
            {{ item ? item.name : '' }}
          </h4>
        </b-col>
        <b-col
          v-if="isPanelAddEditMode"
          cols="auto"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            role="button"
            @click="onClickCancelEdit"
          />
        </b-col>
      </b-row>
      <div
        v-for="option in options"
        :key="option.id"
        class="orders-add__select-group"
      >
        <div
          v-if="option.name"
          class="font-weight-bolder"
        >
          {{ option.name }}
        </div>
        <div
          v-for="optionItem in option.items"
          :key="optionItem.id"
        >
          <b-form-radio
            v-if="option.type === 'choose'"
            :value="optionItem.id"
            :checked="selectedOptions.find(i => i === optionItem.id)"
            @change="onChangeOptionItemChoose($event, option.items)"
          >
            <span class="d-flex justify-content-between">
              <span>{{ optionItem.name }}</span>
              <span
                v-if="optionItem.addPrice"
                class="text-nowrap ml-1"
              >
                + <currency-symbol>{{ optionItem.addPrice }}</currency-symbol>
              </span>
            </span>
          </b-form-radio>
          <b-form-checkbox
            v-else
            :value="optionItem.id"
            :checked="selectedOptions.find(i => i === optionItem.id)"
            @change="onChangeOptionItemNotChoose(!!$event, optionItem.id)"
          >
            <span class="d-flex justify-content-between">
              <span>{{ optionItem.name }}</span>
              <span
                v-if="optionItem.price"
                class="text-nowrap ml-1"
              >
                + <currency-symbol>{{ optionItem.price }}</currency-symbol>
              </span>
            </span>
          </b-form-checkbox>
        </div>
      </div>
      <div
        v-for="extra in extras"
        :key="extra.id"
        class="orders-add__select-group"
      >
        <div
          v-if="extra.name"
          class="font-weight-bolder"
        >
          {{ extra.name }}
        </div>
        <b-form-checkbox
          v-for="extraItem in extra.items"
          :key="extraItem.id"
          :value="extraItem.originalMenuItemId || extraItem.id"
          :checked="selectedExtras.find(i => i === extraItem.originalMenuItemId || i === extraItem.id)"
          @change="onChangeExtraItem(!!$event, extraItem.originalMenuItemId || extraItem.id)"
        >
          <span class="d-flex justify-content-between">
            <span>{{ extraItem.name }}</span>
            <span
              v-if="extraItem.addPrice"
              class="text-nowrap ml-1"
            >
              + <currency-symbol>{{ extraItem.addPrice }}</currency-symbol>
            </span>
          </span>
        </b-form-checkbox>
      </div>
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <div class="orders-add__menus">
            <b-badge
              v-for="menu in menus"
              :key="menu.id"
              pill
              role="button"
              :disabled="true"
              :variant="selectedMenuId === menu.id ? 'primary' : 'light-primary'"
              @click="onClickMenu(menu.id)"
            >
              {{ menu.name }}
            </b-badge>
          </div>
        </b-col>
        <b-col cols="auto">
          <feather-icon
            icon="XIcon"
            size="20"
            role="button"
            @click="onClickClose"
          />
        </b-col>
      </b-row>
      <div>
        <div class="orders-add__categories">
          <div
            v-if="categories.length"
            class="orders-add__categories-container"
          >
            <div
              v-for="category in categories"
              :key="category.id"
              :class="[
                'orders-add__card orders-add__card_category',
                { 'orders-add__card_active': selectedCategoryId === category.id },
              ]"
              :title="category.name"
              @click="onClickCategory(category.id)"
            >
              <div class="orders-add__card-value">
                {{ category.name }}
              </div>
            </div>
          </div>
          <small v-else>
            {{ $t('orderNoCategoryInMenuMessage') }}
          </small>
        </div>
        <div class="orders-add__items">
          <div
            v-if="items.length"
            class="orders-add__items-container"
          >
            <div
              v-for="menuItem in items"
              :key="menuItem.id"
              :class="[
                'orders-add__card orders-add__card_item',
                { 'orders-add__card_active': selectedOfferId === menuItem.id },
              ]"
              :title="menuItem.name"
              @click="onClickMenuItem(menuItem.id)"
            >
              <div class="orders-add__card-value">
                {{ menuItem.name }}
              </div>
            </div>
          </div>
          <small v-else>
            {{ $t('ordersNoItemsInCategoryMessage') }}
          </small>
        </div>
      </div>
    </template>
    <div class="orders-add__footer">
      <div class="orders-add__note">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="ClipboardIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="note"
            :placeholder="$t('ordersNotePlaceholder')"
          />
        </b-input-group>
      </div>
      <b-row class="align-items-center">
        <b-col>
          <h5 class="mb-0">
            <currency-symbol>{{ getFormattedPrice(price) }}</currency-symbol>
          </h5>
        </b-col>
        <b-col
          cols="auto"
          class="d-flex align-items-center"
        >
          <div class="orders-add__counter">
            <b-form-spinbutton
              v-model="quantity"
              size="sm"
              min="1"
              max="50"
              inline
              class="mr-2"
              :disabled="!selectedOfferId"
            />
          </div>
          <b-button
            variant="primary"
            :disabled="!selectedOfferId"
            @click="onClickConfirm"
          >
            <template v-if="isPanelAddEditMode">
              {{ $t('ordersEditItemModeButtonName') }}
            </template>
            <template v-else>
              {{ $t('ordersAddToOrderButtonName') }}
            </template>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormRadio,
  BFormCheckbox,
  BBadge,
  BButton,
  BFormSpinbutton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinFormatter from '@/mixins/formatter'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'OrdersAdd',
  components: {
    BCard,
    BRow,
    BCol,
    BFormRadio,
    BFormCheckbox,
    BBadge,
    BButton,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    CurrencySymbol,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    isPanelAddComplex: {
      type: Boolean,
      required: true,
      default: false,
    },
    isPanelAddEditMode: {
      type: Boolean,
      required: true,
      default: false,
    },
    menus: {
      type: Array,
      required: true,
      default: () => [],
    },
    editToBeAddedItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      selectedMenuId: '',
      selectedCategoryId: '',
      selectedOfferId: null,
      selectedOptions: [],
      selectedExtras: [],

      note: '',
      quantity: 1,
    }
  },
  computed: {
    menusFiltered() {
      return this.menus.map(i => ({
        ...i,
        categories: i.categories.map(j => ({
          ...j,
          items: j.items
            .filter(k => k.isEnabled && k.isVisible)
            .map(k => ({
              ...k,
              options: k.options
                .map(m => ({ ...m, items: m.items.filter(n => n.isEnabled && n.isVisible) }))
                .filter(m => {
                  if (m.type === 'choose') {
                    return m.items.length && m.items.find(n => n.isEnabled && n.isVisible)
                  }
                  return m.items.length
                }),
            })),
        })),
      }))
    },
    itemsAll() {
      return this.menusFiltered.flatMap(i => i.categories.flatMap(j => j.items))
    },

    categories() {
      if (this.selectedMenuId) {
        return this.menusFiltered.find(i => i.id === this.selectedMenuId).categories
      }
      return []
    },
    items() {
      if (this.selectedCategoryId) {
        return this.categories.find(i => i.id === this.selectedCategoryId).items
      }
      return []
    },

    item() {
      if (this.selectedOfferId) {
        return this.itemsAll.find(i => i.id === this.selectedOfferId)
      }
      return null
    },
    options() {
      if (this.item) {
        return this.item.options
      }
      return []
    },
    extras() {
      if (this.item) {
        return this.item.extras
          .map(i => ({
            ...i,
            items: i.items
              .filter(j => {
                if (j.type === 'SimpleItem') {
                  return j.isEnabled && j.isVisible
                }
                return this.itemsAll.find(k => k.originalMenuItemId === j.id)
              })
              .map(j => {
                if (j.type === 'SimpleItem') {
                  return j
                }
                return {
                  ...this.itemsAll.find(k => k.originalMenuItemId === j.id),
                  addPrice: j.addPrice,
                }
              }),
          }))
          .filter(i => i.name && i.items.length)
      }
      return []
    },

    price() {
      let result = 0

      if (this.selectedOfferId) {
        result += this.item.price

        this.selectedOptions.forEach(i => {
          const option = this.options.find(j => j.items.find(k => k.id === i))
          if (option) {
            const optionItem = option.items.find(j => j.id === i)
            if (optionItem) {
              result += optionItem.addPrice
            }
          }
        })

        this.selectedExtras.forEach(i => {
          const extra = this.extras.find(j => j.items.find(k => k.id === i || k.originalMenuItemId === i))
          if (extra) {
            const extraItem = extra.items.find(j => j.id === i || j.originalMenuItemId === i)
            if (extraItem) {
              result += extraItem.addPrice
            }
          }
        })
      }

      return result
    },
  },
  watch: {
    editToBeAddedItem: {
      handler(newValue) {
        if (newValue.offerId) {
          this.selectedMenuId = this.menusFiltered
            .find(i => i.categories.find(j => j.items.find(k => k.id === newValue.offerId))).id
          this.selectedCategoryId = this.categories.find(i => i.items.find(j => j.id === newValue.offerId)).id

          this.selectedOfferId = newValue.offerId
          this.selectedOptions = [...newValue.options]
          this.selectedExtras = [...newValue.extras]
          this.note = newValue.note
          this.quantity = newValue.quantity

          if (this.options.length || this.extras.length) {
            this.$emit('updateComplexPanel', true)
          } else {
            this.$emit('updateComplexPanel', false)
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (!this.editToBeAddedItem.offerId) {
      if (this.menus.length) {
        this.selectedMenuId = this.menus[0].id
      }
      if (this.categories.length) {
        this.selectedCategoryId = this.categories[0].id
      }
    }
  },
  methods: {
    onChangeOptionItemChoose(event, items) {
      this.selectedOptions = [...this.selectedOptions.filter(i => !items.find(j => j.id === i)), event]
    },
    onChangeOptionItemNotChoose(isAdd, id) {
      if (isAdd) {
        this.selectedOptions = [...this.selectedOptions, id]
      } else {
        this.selectedOptions = this.selectedOptions.filter(i => i !== id)
      }
    },
    onChangeExtraItem(isAdd, id) {
      if (isAdd) {
        this.selectedExtras = [...this.selectedExtras, id]
      } else {
        this.selectedExtras = this.selectedExtras.filter(i => i !== id)
      }
    },
    onClickMenu(menuId) {
      if (this.isPanelAddEditMode) {
        return
      }

      this.selectedMenuId = menuId
      if (this.categories.length) {
        this.selectedCategoryId = this.categories[0].id
      }
      this.selectedOfferId = ''
    },
    onClickCategory(categoryId) {
      if (this.isPanelAddEditMode) {
        return
      }

      this.selectedCategoryId = categoryId
      this.selectedOfferId = ''
    },
    onClickClose() {
      this.$emit('close')
    },
    onClickMenuItem(offerId) {
      if (this.isPanelAddEditMode) {
        return
      }

      if (this.selectedOfferId === offerId) {
        this.selectedOfferId = ''
      } else {
        this.selectedOfferId = offerId
        this.selectedOptions = []
        this.selectedExtras = []
        this.quantity = 1
      }

      if (this.options.length || this.extras.length) {
        let selectedOptions = []
        const optionsChoose = this.options.filter(i => i.type === 'choose')
        optionsChoose.forEach(chooseOption => {
          const chooseOptionItemDefault = chooseOption.items.find(chooseOptionItem => chooseOptionItem.default)
          if (chooseOptionItemDefault) {
            selectedOptions = [...selectedOptions, chooseOptionItemDefault.id]
          } else {
            const chooseOptionItemsRest = chooseOption.items.filter(chooseOptionItem => !chooseOptionItem.default)
            if (chooseOptionItemsRest.length) {
              selectedOptions = [...selectedOptions, chooseOptionItemsRest[0].id]
            }
          }
        })
        this.selectedOptions = [...selectedOptions]

        this.$emit('updateComplexPanel', true)
      }
    },
    onClickConfirm() {
      if (this.isPanelAddEditMode) {
        this.$emit('updateAddToOrderItem', {
          id: this.editToBeAddedItem.id,
          offerId: this.selectedOfferId,
          options: this.selectedOptions,
          extras: this.selectedExtras,
          note: this.note,
          quantity: this.quantity,
        })
        this.$emit('updatePanelEditMode', false)
        this.$emit('clearEditToBeAddedItem')
      } else {
        this.$emit('addToOrderItem', {
          id: uuidv4(),
          offerId: this.selectedOfferId,
          options: this.selectedOptions,
          extras: this.selectedExtras,
          note: this.note,
          quantity: this.quantity,
        })
      }

      this.$emit('updateComplexPanel', false)
      this.selectedOfferId = ''
      this.note = ''
      this.quantity = 1
    },
    onClickCancelEdit() {
      this.$emit('updatePanelEditMode', false)
      this.$emit('updateComplexPanel', false)
      this.$emit('clearEditToBeAddedItem')
      this.selectedOfferId = ''
      this.note = ''
      this.quantity = 1
    },
    onClickCloseComplexPanel() {
      this.$emit('updateComplexPanel', false)
      this.selectedOfferId = ''
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  .orders-add
    margin-bottom: 0
    &__menus
      margin-bottom: -8px
      margin-right: -10px
      .badge
        padding: 6px 9px
        margin-bottom: 8px
        margin-right: 10px
    &__card
      display: flex
      align-items: center
      justify-content: center
      padding: 6px
      margin-right: 21px
      margin-bottom: 16px
      width: 124px
      height: 90px
      border-radius: 12px
      cursor: pointer
      text-align: center
      color: #5A5767
      border: 1px solid $border-color
      overflow: hidden
      &_category
        font-weight: $font-weight-bolder
        background: rgba($primary, 0.04)
        &.orders-add__card_active
          background-color: $primary
          border-color: $primary
          color: $white
      &_item
        font-weight: $font-weight-bold
        background-color: $white
        &.orders-add__card_active
          background-color: rgba($primary, 0.2)
          border: 1px solid $primary
          color: $primary
      &-value
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 4
        overflow: hidden
        text-overflow: ellipsis
    &__categories
      padding: 21px 0
      &-container
        display: flex
        flex-wrap: wrap
        margin-right: -22px
        margin-bottom: -16px
    &__items
      background-color: #fcfcfc
      padding: 21px
      margin: 0 -21px
      border-top: 1px solid $border-color
      &-container
        display: flex
        flex-wrap: wrap
        margin-right: -22px
        margin-bottom: -16px
    &__footer
      position: relative
      padding-top: 12px
      &::before
        content: ''
        display: block
        height: 1px
        position: absolute
        top: 0
        left: -21px
        right: -21px
        background-color: $border-color
    &__note
      margin: 0 -21px 12px
      padding: 0 21px 12px
      border-bottom: 1px solid $border-color
    &__select-group
      margin-top: 21px
      margin-bottom: 21px
      .custom-control
        margin: 10px 0
        &-label
          display: block
</style>
