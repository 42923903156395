<template>
  <svg
    class="icon-custom"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.333008 10.8882V12.0002C0.333008 12.9194 2.57259 13.6668 5.33301 13.6668C8.09342 13.6668 10.333 12.9194 10.333 12.0002V10.8882C9.25749 11.646 7.29134 12.0002 5.33301 12.0002C3.37467 12.0002 1.40853 11.646 0.333008 10.8882ZM8.66634 3.66683C11.4268 3.66683 13.6663 2.91943 13.6663 2.00016C13.6663 1.08089 11.4268 0.333496 8.66634 0.333496C5.90592 0.333496 3.66634 1.08089 3.66634 2.00016C3.66634 2.91943 5.90592 3.66683 8.66634 3.66683ZM0.333008 8.15641V9.50016C0.333008 10.4194 2.57259 11.1668 5.33301 11.1668C8.09342 11.1668 10.333 10.4194 10.333 9.50016V8.15641C9.25749 9.04183 7.28874 9.50016 5.33301 9.50016C3.37728 9.50016 1.40853 9.04183 0.333008 8.15641ZM11.1663 8.44287C12.6585 8.15381 13.6663 7.61735 13.6663 7.00016V5.88818C13.0622 6.31527 12.1742 6.60693 11.1663 6.78662V8.44287ZM5.33301 4.50016C2.57259 4.50016 0.333008 5.43245 0.333008 6.5835C0.333008 7.73454 2.57259 8.66683 5.33301 8.66683C8.09342 8.66683 10.333 7.73454 10.333 6.5835C10.333 5.43245 8.09342 4.50016 5.33301 4.50016ZM11.0439 5.96631C12.6064 5.68506 13.6663 5.13298 13.6663 4.50016V3.38818C12.7419 4.04183 11.1533 4.39339 9.48145 4.47673C10.2497 4.84912 10.8148 5.34912 11.0439 5.96631Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDatabase',
}
</script>
